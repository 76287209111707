import { Injectable } from '@angular/core';

import { CookieStorage } from '../shared/storage';

export interface StorageDuration {
  infinite?: boolean;
  duration?: Date;
}

@Injectable()
export class StorageService {
  private readonly storage: CookieStorage;

  constructor() {
    this.storage = new CookieStorage();
  }

  getItem(key: string): string {
    return this.storage.getItem(key);
  }

  setItem(key: string, value: string, storageDuration?: StorageDuration) {
    this.storage.setItem(key, value, storageDuration);
  }

  setTldItem(key: string, value: string, storageDuration?: StorageDuration) {
    this.storage.setTldItem(key, value, storageDuration);
  }

  removeItem(key: string) {
    this.storage.removeItem(key);
  }

  removeTldItem(key: string) {
    this.storage.removeTldItem(key);
  }
}
