import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, ReplaySubject } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';

import { userLoggedIn, userLoggedOut } from '@app/auth/auth.actions';

import { ApiHeaderService } from './api-header.service';
import { Auth0AuthService } from './auth0-auth.service';

@Injectable({
  providedIn: 'root',
})
/**
 * Class AuthService - parent, the api interface that can hande auth, exposed to all the apps
 */
export class AuthService {
  private _initialized$ = new ReplaySubject<boolean>(1);

  constructor(
    private apiHeaderService: ApiHeaderService,
    private auth0AuthService: Auth0AuthService,
    private store: Store,
  ) {}

  get initialized$(): Observable<boolean> {
    return this._initialized$.asObservable();
  }

  init(): Observable<boolean> {
    const isAuth0Configured = this.auth0AuthService.isConfigured();

    if (isAuth0Configured) {
      this.auth0AuthService
        .init()
        .pipe(
          switchMap(() => this.auth0AuthService.getToken()),
          tap(token => {
            this.setToken(token);
            this._initialized$.next(true);
          }),
        )
        .subscribe();
    } else {
      this._initialized$.next(false);
    }
    return this.initialized$;
  }

  getToken(): Observable<string | null> {
    const getToken$ = this.initialized$.pipe(switchMap(() => this.auth0AuthService.getToken()));
    getToken$.subscribe();
    return getToken$;
  }

  goLogin(path: string | null = null, fragment?: string): void {
    this.apiHeaderService.revokeAccessToken();
    this.initialized$.pipe(tap(() => this.auth0AuthService.goLogin(path, fragment))).subscribe();
  }

  logout(returnURI?: string): void {
    this.apiHeaderService.revokeAccessToken();
    const logout$ = this.initialized$.pipe(map(() => this.auth0AuthService.logout(returnURI)));
    logout$.subscribe();
    this.store.dispatch(userLoggedOut());
  }

  setToken(token: string | null): void {
    if (!token) {
      this.apiHeaderService.revokeAccessToken();
      return;
    }
    this.apiHeaderService.setAccessToken(token);
    this.auth0AuthService.setToken(token);
    this.store.dispatch(userLoggedIn());
  }

  /**
   * Returns whether a user is currently authenticated or not.
   *
   * @todo Check whether the token's expiration date is still valid
   * @returns
   */
  isLoggedIn(): boolean {
    return !!this.apiHeaderService.token;
  }
}
