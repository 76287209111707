import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { PatientPreferencesGraphqlService } from '@app/account/connected-accounts/patient-preferences-graphql.service';
import { FeatureFlagSelectors } from '@app/core/feature-flags/feature-flag.selectors';
import { FeatureFlagVariants, FeatureFlags } from '@app/core/feature-flags/feature-flags';
import { LaunchDarklyService } from '@app/core/feature-flags/launchdarkly.service';
import { LinksService } from '@app/core/links.service';
import { LoginService } from '@app/core/login.service';
import { InviteFriendsModalComponent } from '@app/shared/referrals/invite-friends-modal.component';

import { AppStoreRedirectService } from '../../app-store-redirect.service';
import { User } from '../../user';
import { PrincipalUser } from '../__generated__/principal-user-graphql.service.types';
import { TargetUser } from '../__generated__/valid-target-users-graphql.service.types';
import { NavbarAnalyticsService } from '../navbar-analytics.service';

export enum NavMenuSection {
  CARE_PLAN = 'CARE_PLAN',
  HEALTH_RECORD = 'HEALTH_RECORD',
  ACCOUNT = 'ACCOUNT',
}

@Component({
  selector: 'om-navbar-mobile',
  templateUrl: 'navbar-mobile.component.html',
  styleUrls: ['../navbar-common.scss', 'navbar-mobile.component.scss'],
})
export class NavbarMobileComponent implements OnChanges, OnInit {
  @Input() currentUser: User;
  @Input() principalUser: PrincipalUser;
  @Input() targetUsers: TargetUser[];

  @Input() canBookVisit: boolean;
  @Input() canRegisterKid: boolean;
  @Input() enrolledInMindsetPlus: boolean;
  @Input() inactiveMembership: boolean;
  @Input() inviteCta: string;
  @Input() displayInviteCta: boolean;

  @Output() principalUserClicked = new EventEmitter<void>();
  @Output() targetUserClicked = new EventEmitter<TargetUser>();

  connectedAccountsActive$: Observable<boolean>;
  loginAndSecurity$: Observable<boolean>;
  requestRecordsPageTitle$: Observable<string>;
  currentUserRole: string;
  directSignupEligible: boolean;
  mobileAppLink: string | void;
  pediatric: boolean;
  virtual: boolean;

  navMenuOpen = false;
  navMenuSection = NavMenuSection;
  navMenuExpanded = {
    [NavMenuSection.ACCOUNT]: false,
    [NavMenuSection.CARE_PLAN]: false,
    [NavMenuSection.HEALTH_RECORD]: false,
  };

  profileMenuOpen = false;

  private readonly ANALYTICS_APP_LINK_LOCATION = 'slide-menu';

  constructor(
    private appStoreRedirectService: AppStoreRedirectService,
    private featureFlagSelectors: FeatureFlagSelectors,
    public links: LinksService,
    private loginService: LoginService,
    private modal: NgbModal,
    private navbarAnalyticsService: NavbarAnalyticsService,
    private patientPreferencesGraphqlService: PatientPreferencesGraphqlService,
    private launchDarklyService: LaunchDarklyService,
  ) {}

  trackMessagesLink = () => this.navbarAnalyticsService.trackMessagesLink();
  trackHomeLink = () => this.navbarAnalyticsService.trackHomeLink();
  trackRegisterChildLink = () => this.navbarAnalyticsService.trackRegisterChildLinkSelected();
  trackDirectSignupLink = () => this.navbarAnalyticsService.trackDirectSignupStarted();
  trackBookVisitLink = () => this.navbarAnalyticsService.trackBookVisitLink();
  trackTasksLink = () => this.navbarAnalyticsService.trackTasksLink();
  trackHelpCenterLink = () => this.navbarAnalyticsService.trackHelpCenterLink();

  ngOnInit(): void {
    this.initCurrentUserData(this.currentUser);

    this.connectedAccountsActive$ = this.patientPreferencesGraphqlService.watch().valueChanges.pipe(
      map(preferences => {
        const { healthkitAuthorized } = preferences.data.patient;
        return healthkitAuthorized;
      }),
    );

    this.mobileAppLink = this.appStoreRedirectService.mobileRedirectUrl;

    this.loginAndSecurity$ = this.launchDarklyService
      .featureFlag$<FeatureFlagVariants>(FeatureFlags.LOGIN_AND_SECURITY, FeatureFlagVariants.OFF)
      .pipe(map(flag => flag === FeatureFlagVariants.ON_VARIANT));

    this.requestRecordsPageTitle$ = this.launchDarklyService
      .featureFlag$<boolean>(FeatureFlags.PATIENT_DOWNLOAD_EHI_EXPORT, false)
      .pipe(map(enabled => (enabled ? 'Get My Records' : 'Request Records')));
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.currentUser) {
      this.initCurrentUserData(this.currentUser);
    }
  }

  resetTargetUser() {
    this.principalUserClicked.emit();
  }

  setTargetUser(targetUser: TargetUser) {
    this.targetUserClicked.emit(targetUser);
  }

  logout(e: MouseEvent) {
    e.preventDefault();
    this.loginService.logout();
  }

  openInviteModal() {
    this.modal.open(InviteFriendsModalComponent);
  }

  openNavMenu(event?: MouseEvent) {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
      (<HTMLElement>event.target).blur();
    }

    this.closeProfileMenu(event);
    this.navMenuOpen = true;
  }

  toggleNavMenuSection(section: NavMenuSection, event?: MouseEvent) {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
      (<HTMLElement>event.target).blur();
    }

    this.navMenuExpanded[section] = !this.navMenuExpanded[section];
  }

  closeNavMenu(event?: MouseEvent) {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
      (<HTMLElement>event.target).blur();
    }

    (<NavMenuSection[]>Object.keys(this.navMenuExpanded)).forEach(menu => (this.navMenuExpanded[menu] = false));
    this.navMenuOpen = false;
  }

  openProfileMenu(event?: MouseEvent) {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
      (<HTMLElement>event.target).blur();
    }

    this.closeNavMenu(event);
    this.profileMenuOpen = true;
  }

  closeProfileMenu(event?: MouseEvent) {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
      (<HTMLElement>event.target).blur();
    }

    this.profileMenuOpen = false;
  }

  closeMenus(event?: MouseEvent) {
    this.closeNavMenu(event);
    this.closeProfileMenu(event);
  }

  handleAppLinkClick() {
    this.navbarAnalyticsService.appStoreLinkClicked(this.ANALYTICS_APP_LINK_LOCATION);
  }

  private initCurrentUserData(user: User): void {
    this.currentUserRole =
      this.principalUser && this.currentUser.id.toString() !== this.principalUser.id ? 'Child' : 'Account Owner';
    this.directSignupEligible = user.isDirectSignupEligible;
    this.pediatric = user.isPediatric();
    this.virtual = user.isVirtual;
  }
}
