import includes from 'lodash-es/includes';

export class ActiveFeatureFlagCollection {
  activeFlags: string[];

  constructor(flags: string[]) {
    this.activeFlags = flags;
  }

  static fromApiV2(res: string[]): ActiveFeatureFlagCollection {
    return new ActiveFeatureFlagCollection(res);
  }

  isFeatureActive(feature_name: string): boolean {
    return includes(this.activeFlags, feature_name);
  }
}

export enum BiosInBookingExperiment {
  Off = 'OFF',
  Control = 'ON CONTROL',
  LinkOnly = 'ON LINK ONLY',
  WithSummary = 'ON WITH SUMMARY',
}
