import { Injectable } from '@angular/core';
import { Auth0Client as RawAuth0Client, RedirectLoginResult, createAuth0Client } from '@auth0/auth0-spa-js';
import { RedirectLoginOptions } from '@auth0/auth0-spa-js/src/global';
import get from 'lodash-es/get';
import { from, Observable, ReplaySubject } from 'rxjs';
import { first, switchMap } from 'rxjs/operators';

import { WindowService } from '@app/utils/window.service';

import { ConfigService } from './config.service';

@Injectable({
  providedIn: 'root',
})
export class Auth0Client {
  private readonly auth0Client$: ReplaySubject<RawAuth0Client>;
  private readonly redirectUri: string;
  private readonly configured: boolean;

  constructor(private configService: ConfigService, private windowService: WindowService) {
    this.auth0Client$ = new ReplaySubject<RawAuth0Client>(1);
    this.redirectUri = windowService.getLocationOrigin();

    const audience: string = get(this.configService.json, 'auth0.audience');
    const domain: string = get(this.configService.json, 'auth0.domain');
    const clientId: string = get(this.configService.json, 'auth0.clientId');
    const onelifeUrl: string = get(this.configService.json, 'myoneServer');
    this.configured = !!audience && !!domain && !!clientId && !!onelifeUrl;

    from(
      createAuth0Client({
        domain,
        clientId: clientId,
        authorizationParams: {
          audience,
          redirect_uri: this.redirectUri,
          patient_host: onelifeUrl,
        },
      }),
    ).subscribe({
      next: (client: RawAuth0Client) => this.auth0Client$.next(client),
    });
  }

  isConfigured(): boolean {
    return this.configured;
  }

  login(fragment?: string): void {
    const options: RedirectLoginOptions = {
      authorizationParams: {
        redirect_uri: this.redirectUri,
      },
    };

    if (fragment && fragment.length > 0) {
      options.fragment = fragment;
    }

    this.auth0Client.subscribe({
      next: (client: RawAuth0Client) => client.loginWithRedirect(options),
    });
  }

  logout(): void {
    this.auth0Client.subscribe({
      next: (client: RawAuth0Client) =>
        client.logout({
          logoutParams: { returnTo: this.redirectUri },
        }),
    });
  }

  getAuthResult$(): Observable<RedirectLoginResult> {
    return this.auth0Client.pipe(switchMap((client: RawAuth0Client) => client.handleRedirectCallback()));
  }

  getToken$(): Observable<string> {
    return this.auth0Client.pipe(switchMap((client: RawAuth0Client) => client.getTokenSilently()));
  }

  isAuthenticated$(): Observable<boolean> {
    return this.auth0Client.pipe(switchMap((client: RawAuth0Client) => client.isAuthenticated()));
  }

  private get auth0Client(): Observable<RawAuth0Client> {
    return this.auth0Client$.pipe(first());
  }
}
